import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CommonUtilitiesService {

    constructor() {}

    formatViewTime(timeInSeconds: number): string {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = Math.floor(timeInSeconds % 60);

        return `${minutes}m${seconds}s`;
    }

    generateURLParameters(params: object): string {
        let urlParams = '';
        let count = 0;
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                urlParams += (count === 0 ? '?' : '&') + key + '=' + params[key];
                ++count;
            }
        }
        return urlParams;
    }
}
