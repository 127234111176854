import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { User } from 'app/core/_services/user/user.types';
import { UserService } from 'app/core/_services/user.service';
import {AuthService} from "../../../../core/auth/auth.service";
import {PickService} from "../../../../core/_services/pick.service.";

@Component({
    selector     : 'classy-layout',
    templateUrl  : './classy.component.html',
})
export class ClassyLayoutComponent implements OnInit, OnDestroy
{
    isScreenSmall: boolean;
    navigation: Navigation;
    user;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    topNavOptions = ['NFL', 'NCAAF', 'NBA', 'NCAAM', 'NCAAW', 'NHL', 'MLB', 'NCAA Baseball'];
    selectedTopNavOption = 'NCAAF';

    currJackpot = 9000;
    jackpot = 10000;
    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _authService: AuthService,
        private _pickService: PickService,
        private _router: Router,
        private _navigationService: NavigationService,
        private _userService: UserService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number
    {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
            });

        // Subscribe to the user service
        this.user = this._authService.getCurrentUser();
        this._pickService.getPick({}).subscribe(response =>{
            this.currJackpot = this.jackpot;
            this.jackpot += Math.abs(this.jackpot - 10000 - response.length * 100);
            this.incJackpot(this.currJackpot, this.jackpot, 10);
            setInterval(() => {
                this.currJackpot = this.jackpot;
                this.jackpot += 27;
                this.incJackpot(this.currJackpot, this.jackpot, 3);
            }, 3000);
        })
        // this._userService.user$
        //     .pipe((takeUntil(this._unsubscribeAll)))
        //     .subscribe((user: User) => {
        //         this.user = user;
        //     });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {

                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
    }

    incJackpot(currJackpot, endNbr, timer) {
    if (currJackpot <= endNbr) {
        this.jackpot = currJackpot;
        setTimeout(() => {//Delay a bit before calling the function again.
            this.incJackpot(currJackpot += 1, endNbr, timer);
        }, timer);
    }
}

    selectTopNavOption(navOption){
        // TODO when other sports are available remove below comment
        // this.selectedTopNavOption = navOption;
        this.selectedTopNavOption = 'NCAAF';
    }

    getUserEmail(){
        return this._authService.getCurrentUser().email;
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void
    {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if ( navigation )
        {
            // Toggle the opened status
            navigation.toggle();
        }
    }
}
