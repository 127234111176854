import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { UserService } from 'app/core/_services/user.service';
import { environment } from "../../../environments/environment";
import {CommonUtilitiesService} from "../_services/common-utilities.service";

@Injectable()
export class AuthService
{
    private _authenticated: boolean = false;

    /**
     * Constructor
     */
    constructor(
        private http: HttpClient,
        private router: Router,
        private commonUtilitiesService: CommonUtilitiesService,
    ) { }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string)
    {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string
    {
        return localStorage.getItem('accessToken') ?? '';
    }

    getCurrentUser() {
        return JSON.parse(localStorage.getItem('currentUser'));
    }

    setCurrentUser(userInfo) {
        localStorage.setItem('currentUser', JSON.stringify(userInfo));
    }

    setRegistrationCompleted(regStatus){
        localStorage.setItem('registrationCompleted', JSON.stringify(regStatus));
    }

    getMe() {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        return this.http.get<any>(`${environment.apiUrl}/users/me/${currentUser._id}`);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    login(email: string, loginCode: string) {
        return this.http.post<any>(`${environment.apiUrl}/users/authorize`, {email, loginCode});
    }

    register(email: string, accessCode: string) {
        return this.http.post<any>(`${environment.apiUrl}/users/register`, {email, accessCode});
    }

    access(accessCode: string) {
        return this.http.post<any>(`${environment.apiUrl}/users/register`, {accessCode});
    }

    registerToLogin(params: object){
        this.router.navigate([`/sign-in/${this.commonUtilitiesService.generateURLParameters(params)}`])
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.router.navigate(['/sign-out']);
    }

    forgotPassword(email: string){
        return this.http.post<any>(`${environment.apiUrl}/users/forgot_password`, {email});
    }
    updatePassword(password: string){
        return this.http.put<any>(`${environment.apiUrl}/users/update_password`, {password});
    }

    // check(): Observable<boolean>
    // {
    //     // Check if the user is logged in
    //     if ( this.getCurrentUser() )
    //     {
    //         return of(true);
    //     }
    //
    //     // Check the access token availability
    //     if ( !this.accessToken )
    //     {
    //         return of(false);
    //     }
    //
    //     // Check the access token expire date
    //     if ( AuthUtils.isTokenExpired(this.accessToken) )
    //     {
    //         return of(false);
    //     }
    //
    //     // If the access token exists and it didn't expire, sign in using it
    //     return this.signInUsingToken();
    // }
    //
    // signInUsingToken(): Observable<any>
    // {
    //     // Sign in using the token
    //     return this.http.post('api/auth/sign-in-with-token', {
    //         accessToken: this.accessToken
    //     }).pipe(
    //         catchError(() =>
    //
    //             // Return false
    //             of(false)
    //         ),
    //         switchMap((response: any) => {
    //
    //             // Replace the access token with the new one if it's available on
    //             // the response object.
    //             //
    //             // This is an added optional step for better security. Once you sign
    //             // in using the token, you should generate a new one on the server
    //             // side and attach it to the response object. Then the following
    //             // piece of code can replace the token with the refreshed one.
    //             if ( response.accessToken )
    //             {
    //                 this.accessToken = response.accessToken;
    //             }
    //
    //             // Set the authenticated flag to true
    //             this._authenticated = true;
    //
    //             // Store the user on the user service
    //             this._userService.user = response.user;
    //
    //             // Return true
    //             return of(true);
    //         })
    //     );
    // }
}
