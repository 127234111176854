import { Injectable } from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import {tap} from 'rxjs';
import {Router} from '@angular/router';

@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(private router: Router) { }
  // check if user has logged in and sets Authorization header to jwt token
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers = request.headers.set('Cache-Control', 'no-cache').set('Pragma', 'no-cache').set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');
    const httpRequest = request.clone({ headers });

    return next.handle(httpRequest).pipe(tap(
      (event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // do stuff with response
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 503) {
            console.log('Maintenance, redirecting');
            localStorage.removeItem('currentUser');
            this.router.navigate(['/session/error']);
          }
          else if (err.status === 401) {
            console.log('401 Redirecting to login.');
            localStorage.removeItem('currentUser');
            this.router.navigate(['/sign-in']);
          }
        }
      }
    ));
  }
}

