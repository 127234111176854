import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanMatch, Route, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanMatch
{
    /**
     * Constructor
     */
    constructor(
        private _authService: AuthService,
        private _router: Router
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can match
     *
     * @param route
     * @param segments
     */
    canMatch(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        return this._check(segments);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Check the authenticated status
     *
     * @param segments
     * @private
     */
    private _check(segments: UrlSegment[]): Observable<boolean | UrlTree>
    {
        // Check the authentication status
        if (localStorage.getItem('currentUser')) {
            try {
                this._authService.getMe().subscribe(user => {
                    this._authService.setCurrentUser(user);
                    return of(true);
                });
            } catch(error){
                console.log('err');
                console.log('redirecting to login');
                const redirectURL = `/${segments.join('/')}`;
                const urlTree = this._router.parseUrl(`sign-in?redirectURL=${redirectURL}`);
                return of(urlTree);
            }
        } else {
            console.log('redirecting to login');
            const redirectURL = `/${segments.join('/')}`;
            const urlTree = this._router.parseUrl(`sign-in?redirectURL=${redirectURL}`);
            return of(urlTree);
        }

        // return this._authService.check().pipe(
        //     switchMap((authenticated) => {
        //
        //         // If the user is not authenticated...
        //         if ( !authenticated )
        //         {
        //             // Redirect to the sign-in page with a redirectUrl param
        //             const redirectURL = `/${segments.join('/')}`;
        //             const urlTree = this._router.parseUrl(`sign-in?redirectURL=${redirectURL}`);
        //
        //             return of(urlTree);
        //         }
        //
        //         // Allow the access
        //         return of(true);
        //     })
        // );
    }
}
