import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {CommonUtilitiesService} from "./common-utilities.service";
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, Observable, of, tap} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PickService {
    private _data: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
      private _httpClient: HttpClient,

      private _commonUtilitiesService: CommonUtilitiesService,
  ) { }

    getPick(params: object){
        return this._httpClient.get<any>(
            `${environment.apiUrl}/picks/${this._commonUtilitiesService.generateURLParameters(params)}`
        );
    }

    getUserPickCount(userId: string){
        if (!userId) {
            return of({
                success: false,
                error: 'userId not provided to service function'
            });
        }
        return this._httpClient.get<any>(
            `${environment.apiUrl}/picks/count/${userId}`
        );
    }

    create(params: object){
        return this._httpClient.post<any>(`${environment.apiUrl}/picks/`, params);
    }

    update(pickId: string, params: object) {
        if (!pickId) {
            return of({
                success: false,
                error: 'pickId not provided to service function'
            });
        }
        return this._httpClient.put<any>(`${environment.apiUrl}/picks/${pickId}`, params);
    }

    delete(pickId: string){
        if (!pickId) {
            return of({
                success: false,
                error: 'pickId not provided to service function'
            });
        }
        return this._httpClient.delete<any>(`${environment.apiUrl}/picks/${pickId}`);
    }

    deleteAll(userId: string){
        if (!userId) {
            return of({
                success: false,
                error: 'userId not provided to service function'
            });
        }
        return this._httpClient.delete<any>(`${environment.apiUrl}/picks/all/${userId}`);
    }

    calculateScore(userId: string){
      return this._httpClient.get<any>(
          `${environment.apiUrl}/picks/summary/${userId}`);
    }

    // getRange(start: string, end: string){
    //   const params = {
    //       start,
    //       end,
    //   }
    //     return this._httpClient.get<any>(
    //         `${environment.apiUrl}/time-entries/report/${this._commonUtilitiesService.generateURLParameters(params)}`
    //     );
    // }

    getData(): Observable<any>
    {
        return this._httpClient.get('api/dashboards/project').pipe(
            tap((response: any) => {
                this._data.next(response);
            })
        );
    }
}
